<template>
    <button class="popover__option strip-btn" @click="openMoveCopyItemToWishListModalog">
        <span class="icon"><icon-option-move></icon-option-move></span>
        <span class="label">{{ buttonText }}</span>
    </button>
</template>
<script setup>
import { computed } from "vue";
import { useModalogStore } from "@/stores/modalog";
import IconOptionMove from '~/svg/ui-option-move.svg'
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";

const props =  defineProps({
    isOwner: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        default: {}
    },
    listUuid: {
        type: String,
        default: ''
    }
});

const modalogStore = useModalogStore();
const cardOptionsMenuStore = useCardOptionsMenuStore();

const buttonText = computed(() => {
    return props.isOwner ? 'Move to Wish List' : 'Copy to Wish List';
});

const openMoveCopyItemToWishListModalog = () => {
    modalogStore.showModalog({
        type: 'ModalogMoveCopyItemToWishList',
        props: {
            isOwner: props.isOwner,
            item: props.item,
            listUuid: props.listUuid
        },
        isConfirmDisabled: true,
    });

    cardOptionsMenuStore.closeAll();
}
</script>
