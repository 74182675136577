<script setup>
import Flickity from "flickity";
import FriendCard from "../cards/FriendCard.vue";
import { computed, onMounted, ref } from "vue";
import { usePage } from "@inertiajs/vue3";

const props = defineProps(["experts"]);
const { experts } = props;

const flickity = ref(null);

const flickityInit = () => {
    const flickityInstance = new Flickity(flickity.value, {
        cellAlign: "center",
        freeScroll: true,
        freeScrollFriction: 0.1,
        pageDots: false,
        wrapAround: true,
    });
    flickityInstance.on("dragStart", () =>
        flickityInstance.slider.childNodes.forEach((slide) => {
            slide.style.pointerEvents = "none";
        })
    );
    flickityInstance.on("dragEnd", () =>
        flickityInstance.slider.childNodes.forEach((slide) => {
            slide.style.pointerEvents = "all";
        })
    );
};

const isLoggedIn = computed(() => {
    return !!usePage().props.auth.user?.data;
});

onMounted(() => {
    flickityInit();
});
</script>
<template>
    <div
        class="explore__experts--carousel"
        v-if="experts.length"
        ref="flickity"
    >
        <friend-card
            v-for="expert in experts"
            :is-list-grid-item="false"
            :loggedin="isLoggedIn"
            :user="expert"
        ></friend-card>
    </div>
</template>
