<script setup>
import { computed, ref } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import { useAPIForm } from "@/composables/useAPIForm";
import { useModalogStore } from "@/stores/modalog";
import ImageUpload from "@/Components/Actions/ImageUpload.vue";
import Modalog from "@/Components/Modalogs/Modalog.vue";

const modalogStore = useModalogStore();

const dropzoneRef = ref(null);
const user = usePage().props?.auth?.user?.data ?? null;

const form = useAPIForm({
    imageUuid: null,
});

const cancel = () => {
    modalogStore.closeModalog();
    resetDropzone();
};

const hasProfilePhoto = computed(() => {
    return user?.avatar['profile_photo'];
});

const imageUploaded = (imageUuid) => {
    form.imageUuid = imageUuid;
    modalogStore.isConfirmDisabled = false;
};

const resetDropzone = () => {
    dropzoneRef.value.removeAllFiles();
};

const saveProfilePhoto = () => {
    form.post(route('profile.photo.store', { temp_photo_uuid: form.imageUuid }), {
        onBefore: () => {
            modalogStore.isThrobbing = true;
        },
        onSuccess: () => {
            modalogStore.closeModalog();
            resetDropzone();
            router.reload();
        },
    })
}
</script>
<template>
    <modalog confirm-button-text="Save" :has-header="true">
        <template #title>{{ hasProfilePhoto ? 'Change Profile Photo' : 'Upload Profile Photo' }}</template>
        <template #content>
            <div class="margin-bottom-small margin-top-small">
                <image-upload ref="dropzoneRef" @image-uploaded="imageUploaded"></image-upload>
            </div>
        </template>
        <template #cancelButton>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="saveProfilePhoto" :disabled="modalogStore.isConfirmDisabled">Save</button>
        </template>
    </modalog>
</template>

