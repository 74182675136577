<template>
    <section>
        <!-- TODO: System Alerts go here. Original include: @include('common.system-alert') -->
        <header class="header" role="banner">
            <!-- Nav - Widescreen -->
            <div class="header-widescreen">
                <div class="header-widescreen__container container-wide">
                    <div class="header-widescreen__nav-main">
                        <!-- Brand -->
                        <div class="header-widescreen__brand">
                            <Link
                                class="link"
                                :href="route('home')"
                                title="Gift Hero | The Best Wish List Ever™"
                            >
                                <!-- TODO: Replace when SVG solution in place -->
                                <svg
                                    width="161"
                                    height="30"
                                    viewBox="0 0 161 30"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g fill="none" fill-rule="evenodd">
                                        <path
                                            d="M46.134 22.65c-4.644 0-7.698-3.308-7.698-7.655-.035-2.045.76-4.017 2.2-5.467 1.443-1.45 3.41-2.255 5.456-2.23 2.09-.1 4.14.623 5.704 2.014l-1.654 1.972c-1.12-1.06-2.615-1.632-4.156-1.59-2.736 0-4.814 2.375-4.814 5.26 0 3.074 2.015 5.322 5.047 5.322 1.283.014 2.538-.38 3.583-1.124v-2.78h-3.796v-2.267h6.32v6.234c-1.72 1.49-3.918 2.31-6.193 2.31zM60.066 7.552v14.844h-2.608V7.552M76.67 9.927h-8.44v4.05h7.486v2.376H68.23v6.043h-2.608V7.552H76.67M87.4 22.396h-2.63V9.97h-4.708V7.55h12.046V9.97H87.4"
                                            fill="#124680"
                                        />
                                        <path
                                            fill="#EE3124"
                                            d="M99.063 16.14v6.256h-2.608V7.552h2.608v6.17h7.083v-6.17h2.608v14.844h-2.608V16.14M125.168 9.885h-8.398v3.86h7.443v2.332h-7.443v3.986h8.504v2.333H114.16V7.552h11.008M139.482 22.396l-3.648-5.174h-3.266v5.174h-2.608V7.552h6.616c3.414 0 5.514 1.802 5.514 4.708 0 2.46-1.464 3.944-3.54 4.516l4.006 5.62h-3.074zm-3.118-12.47h-3.796v4.985h3.818c1.865 0 3.053-.975 3.053-2.502 0-1.633-1.146-2.48-3.076-2.48zM152.692 22.65c-2.762.023-5.324-1.44-6.708-3.832-1.383-2.39-1.375-5.342.022-7.726 1.397-2.383 3.967-3.833 6.73-3.795 2.762-.022 5.324 1.44 6.707 3.833 1.384 2.39 1.376 5.342-.02 7.726-1.398 2.383-3.968 3.833-6.73 3.795zm0-12.956c-2.947 0-5.004 2.354-5.004 5.26 0 2.904 2.1 5.3 5.047 5.3 2.948 0 5.005-2.354 5.005-5.26 0-2.904-2.1-5.3-5.048-5.3z"
                                        />
                                        <g>
                                            <path
                                                fill="#FFF"
                                                d="M3.418 7.975h19.367v17.848H3.418z"
                                            />
                                            <path
                                                d="M18.13 3.446c.36-.208.486-.666.283-1.03-.117-.168-.296-.284-.5-.32-.2-.035-.41.012-.577.13-.76.406-1.213 1.112-1.785 1.78.9.137 1.818-.063 2.58-.56zM7.907 3.356c.755.633 1.66.716 2.584.71l.07-.113c-.348-.4-.676-.817-1.05-1.188-.242-.23-.516-.427-.813-.58-.325-.192-.743-.103-.96.205-.207.317-.132.74.17.966z"
                                            />
                                            <path
                                                d="M25.352 5.613c-1.273-.104-2.547-.197-3.82-.295L19.36 5.15c.025-.06.026-.076.034-.08.12-.09.245-.176.365-.267.87-.658 1.335-1.477 1.144-2.616C20.728.897 19.6-.047 18.298.01c-.988.048-1.92.48-2.595 1.202-.784.79-1.5 1.648-2.236 2.487-.384.437-.42.442-.806-.01-.564-.665-1.122-1.336-1.708-1.98C10.282.895 9.366.32 8.342.067 6.36-.35 5.054 1.237 5.2 2.96c.104.785.56 1.48 1.24 1.888.114.08.226.165.366.27-.042.02-.085.037-.13.05-1.865.144-3.73.29-5.594.43C.284 5.655 0 5.932 0 6.675c.004 4.72.006 9.002.008 13.722 0 1.26-.004 2.935 0 4.215H.004v.313c0 .693.462 1.302 1.13 1.487l10.18 2.787 1.657.455c.026.007.058.007.09.013.033-.006.066-.006.098-.013l1.657-.455 10.173-2.787c.67-.185 1.134-.792 1.137-1.487v-.168-.018c.003-6.092.004-12.184.003-18.276.02-.218-.054-.434-.2-.596-.15-.16-.357-.254-.576-.255zm-8.016-3.386c.168-.12.376-.166.578-.13.203.035.382.15.5.32.202.363.076.82-.283 1.03-.762.496-1.68.696-2.58.56.573-.67 1.027-1.375 1.786-1.78zm-9.6.163c.218-.308.636-.397.96-.205.298.153.572.35.814.58.374.37.702.79 1.05 1.188l-.07.113c-.922.006-1.828-.077-2.583-.71-.302-.226-.377-.65-.17-.966zM22.52 8.974l.002 13.727c0 .755-.207 1.024-.928 1.212l-6.026 1.57c-.654.17-.94-.052-.94-.735-.004-1.57-.008-3.14-.01-4.71v-.426l-3.122.667c.012 1.36.007 3.143.005 4.503 0 .638-.29.868-.902.71-2.055-.53-4.11-1.064-6.163-1.6-.51-.078-.87-.538-.825-1.052-.002-4.522-.002-9.045 0-13.568 0-.84.364-1.12 1.19-.976 1.956.336 3.912.667 5.87.994.66.11.832.3.832.96v4.991l3.115-.748v-.377l.01-3.94c.002-.54.176-.775.703-.867 2.07-.358 4.14-.71 6.21-1.053 0 0 .98-.243.98.72z"
                                                fill="#EE3124"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                            <h1 class="visuallyhidden">Gift Hero</h1>
                        </div>
                        <!-- Main Navigation -->
                        <nav class="header-widescreen__links">
                            <!-- Main Navigation / Logged In -->
                            <template v-if="user !== null">
                                <ul class="nav-links nav-links--primary">
                                    <li>
                                        <Link
                                            :href="
                                                route('user.giftlists', {
                                                    username: user.username,
                                                })
                                            "
                                            :class="{
                                                selected:
                                                    $page.component.startsWith(
                                                        'MyGiftHero/'
                                                    ),
                                            }"
                                            title="My Gift Hero"
                                            >My Gift Hero</Link>
                                    </li>
                                    <li>
                                        <Link
                                            :href="route('friends.index')"
                                            :class="{
                                                selected:
                                                    $page.component.startsWith(
                                                        'Friends/'
                                                    ),
                                            }"
                                            title="Friends">Friends</Link>
                                    </li>
                                    <li>
                                        <Link
                                            :href="route('explore')"
                                            :class="{
                                                selected:
                                                    $page.component.startsWith(
                                                        'GiftIdeas'
                                                    ),
                                            }"
                                            title="Gift Ideas">Gift Ideas</Link
                                        >
                                    </li>
                                </ul>
                                <!-- Secondary -->
                                <ul class="nav-links nav-links--secondary">
                                    <li>
                                        <Link :href="route('about-us')" title="About">About</Link>
                                    </li>
<!--                                    <li>-->
<!--                                        <Link :href="route('blog')" title="Blog">Blog</Link>-->
<!--                                    </li>-->
                                    <li>
                                        <a
                                            href="https://support.gifthero.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Help"
                                            >Help</a
                                        >
                                    </li>
                                </ul>
                            </template>
                            <template v-else>
                                <!-- Main Navigation / Logged Out -->
                                <ul class="nav-links nav-links--primary">
                                    <li>
                                        <Link :href="route('register')" title="Create a Wish List">Create a Wish List</Link>
                                    </li>
                                    <li>
                                        <Link :href="route('friends.find-friends')" title="Find Friends">Find Friends</Link>
                                    </li>
                                    <li>
                                        <Link
                                            :href="route('explore')"
                                            :class="{
                                                selected:
                                                    $page.component ===
                                                    'Explore',
                                            }"
                                            title="Gift Ideas"
                                            >Gift Ideas</Link>
                                    </li>
                                </ul>
                                <!-- Secondary -->
                                <ul class="nav-links nav-links--secondary">
                                    <li>
                                        <Link
                                            :href="route('about-us')"
                                            title="About"
                                            >About</Link>
                                    </li>
<!--                                    <li>-->
<!--                                        <Link :href="route('blog')" title="Blog">Blog</Link>-->
<!--                                    </li>-->
                                    <li>
                                        <a
                                            href="https://support.gifthero.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Help"
                                            >Help</a
                                        >
                                    </li>
                                </ul>
                            </template>
                        </nav>
                        <!-- Action Links -->
                        <div class="header-widescreen__nav-action" v-if="!user">
                            <ul class="nav-links nav-links--action">
                                <li>
                                    <Link :href="route('login')" title="Log In">Log In</Link>
                                </li>
                                <li>
                                    <Link :href="route('register')" title="Sign Up">Sign Up</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- -Account -->
                    <nav class="header-widescreen__nav-account" v-if="user">
                        <!-- TODO -->
                        <!-- <div class="notifications">
                        <button class="notifications__toggle" type="button" :class="{ 'is-active': notificationsPanelIsVisible }" @click.stop="toggleNotificationsPanel(); markNotificationsRead()" v-click-outside="closeUserMenu">
                            <span class="notifications__toggle-icon" :class="{ 'notifications__toggle-icon--unread': unreadNotifications }">
                                <svg width="72" height="72" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg"><title>icon-ui-notification-bell</title><path d="M42.508 63c.812.13 1.236.674 1.26 1.536.101 3.751-4.135 7.464-7.245 7.464-3.404 0-7.455-3.673-7.287-7.455.036-.826.387-1.38 1.151-1.53L42.508 63zM13.85 43.634v-4.491c0-4.811-.157-9.626.057-14.43.338-7.812 4.873-13.25 12.725-16.594.996-.425 1.461-.805 1.546-1.866C28.51 2.06 33.772-.896 38.669.248c3.57.83 5.889 3.234 6.204 6.518.061.632.33.909.972 1.144 7.901 2.904 13.455 9.709 13.283 17.724-.127 5.751-.03 11.508-.033 17.262v.708c1.875.76 3.655 1.316 5.24 2.163 2.484 1.327 3.76 3.346 3.66 5.987-.054 1.383 0 2.766 0 4.149-.01 1.706-.968 2.591-2.86 2.593-19.116.005-38.232.005-57.35 0-1.711 0-2.7-.773-2.743-2.283-.054-1.978-.086-3.968.097-5.935.197-2.098 1.675-3.596 3.648-4.61 1.537-.792 3.253-1.321 5.063-2.034z" fill-rule="nonzero"/></svg>
                            </span>
                        </button>
                    </div> -->
                        <!-- TODO: Implement methods -->
                        <div
                            class="header-widescreen__member"
                            v-click-outside="closeUserMenu"
                        >
                            <button
                                type="button"
                                class="header-widescreen__member--toggle"
                                :class="{ 'is-active': userMenuIsVisible }"
                                @click.stop="toggleUserMenu"
                            >
                                <span class="header-widescreen__member--icon">
                                    <img
                                        :src="user.avatar.profile_photo"
                                        :alt="user.full_name"
                                        width="48"
                                        height="48"
                                    />
                                </span>
                                <span
                                    class="header-widescreen__member--arrow"
                                    aria-hidden="true"
                                >
                                    <svg
                                        width="72"
                                        height="46"
                                        viewBox="0 0 72 46"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>icon-ui-arrow-down</title>
                                        <path
                                            d="M36 27.924L9.427 1 1 9.538 36 45 71 9.538 62.573 1z"
                                            fill-rule="nonzero"
                                        />
                                    </svg>
                                </span>
                            </button>
                            <div
                                class="header-widescreen__member--menu"
                                :class="{ 'is-visible': userMenuIsVisible }"
                            >
                                <h2 class="visuallyhidden">Your Account</h2>
                                <div class="menu-info">
                                    <Link
                                        class="displayname"
                                        :href="
                                            route('user.giftlists', {
                                                username: user.username,
                                            })
                                        "
                                        >{{ user.full_name }}</Link>
                                    <Link
                                        class="handle"
                                        :href="
                                            route('user.giftlists', {
                                                username: user.username,
                                            })
                                        "
                                        >@{{ user.username }}</Link>
                                </div>
                                <nav class="menu-group" role="navigation">
                                    <ul class="menu-navigation">
                                        <li>
                                            <Link
                                                :href="route('profile.show')"
                                                title="Edit your account settings"
                                                >Account Settings</Link>
                                        </li>
                                        <li>
                                            <Link
                                                :href="route('logout')"
                                                title="Log Out"
                                                @click.stop="logout"
                                                ><small>Log Out</small></Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </nav>
                    <!-- .header__nav-wrap -->
                </div>
            </div>
            <!-- .header-widescreen -->
            <!-- Nav - Mobile -->
            <div class="header-mobile">
                <div class="header-mobile__bar">
                    <div class="header-mobile__container">
                        <div class="header-mobile__nav-main">
                            <div class="header-mobile__brand">
                                <a
                                    class="link"
                                    :href="route('home')"
                                    title="Gift Hero | The Best Wish List Ever™"
                                >
                                    <!-- TODO: Replace when SVG solution in place -->
                                    <svg
                                        width="161"
                                        height="30"
                                        viewBox="0 0 161 30"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g fill="none" fill-rule="evenodd">
                                            <path
                                                d="M46.134 22.65c-4.644 0-7.698-3.308-7.698-7.655-.035-2.045.76-4.017 2.2-5.467 1.443-1.45 3.41-2.255 5.456-2.23 2.09-.1 4.14.623 5.704 2.014l-1.654 1.972c-1.12-1.06-2.615-1.632-4.156-1.59-2.736 0-4.814 2.375-4.814 5.26 0 3.074 2.015 5.322 5.047 5.322 1.283.014 2.538-.38 3.583-1.124v-2.78h-3.796v-2.267h6.32v6.234c-1.72 1.49-3.918 2.31-6.193 2.31zM60.066 7.552v14.844h-2.608V7.552M76.67 9.927h-8.44v4.05h7.486v2.376H68.23v6.043h-2.608V7.552H76.67M87.4 22.396h-2.63V9.97h-4.708V7.55h12.046V9.97H87.4"
                                                fill="#124680"
                                            />
                                            <path
                                                fill="#EE3124"
                                                d="M99.063 16.14v6.256h-2.608V7.552h2.608v6.17h7.083v-6.17h2.608v14.844h-2.608V16.14M125.168 9.885h-8.398v3.86h7.443v2.332h-7.443v3.986h8.504v2.333H114.16V7.552h11.008M139.482 22.396l-3.648-5.174h-3.266v5.174h-2.608V7.552h6.616c3.414 0 5.514 1.802 5.514 4.708 0 2.46-1.464 3.944-3.54 4.516l4.006 5.62h-3.074zm-3.118-12.47h-3.796v4.985h3.818c1.865 0 3.053-.975 3.053-2.502 0-1.633-1.146-2.48-3.076-2.48zM152.692 22.65c-2.762.023-5.324-1.44-6.708-3.832-1.383-2.39-1.375-5.342.022-7.726 1.397-2.383 3.967-3.833 6.73-3.795 2.762-.022 5.324 1.44 6.707 3.833 1.384 2.39 1.376 5.342-.02 7.726-1.398 2.383-3.968 3.833-6.73 3.795zm0-12.956c-2.947 0-5.004 2.354-5.004 5.26 0 2.904 2.1 5.3 5.047 5.3 2.948 0 5.005-2.354 5.005-5.26 0-2.904-2.1-5.3-5.048-5.3z"
                                            />
                                            <g>
                                                <path
                                                    fill="#FFF"
                                                    d="M3.418 7.975h19.367v17.848H3.418z"
                                                />
                                                <path
                                                    d="M18.13 3.446c.36-.208.486-.666.283-1.03-.117-.168-.296-.284-.5-.32-.2-.035-.41.012-.577.13-.76.406-1.213 1.112-1.785 1.78.9.137 1.818-.063 2.58-.56zM7.907 3.356c.755.633 1.66.716 2.584.71l.07-.113c-.348-.4-.676-.817-1.05-1.188-.242-.23-.516-.427-.813-.58-.325-.192-.743-.103-.96.205-.207.317-.132.74.17.966z"
                                                />
                                                <path
                                                    d="M25.352 5.613c-1.273-.104-2.547-.197-3.82-.295L19.36 5.15c.025-.06.026-.076.034-.08.12-.09.245-.176.365-.267.87-.658 1.335-1.477 1.144-2.616C20.728.897 19.6-.047 18.298.01c-.988.048-1.92.48-2.595 1.202-.784.79-1.5 1.648-2.236 2.487-.384.437-.42.442-.806-.01-.564-.665-1.122-1.336-1.708-1.98C10.282.895 9.366.32 8.342.067 6.36-.35 5.054 1.237 5.2 2.96c.104.785.56 1.48 1.24 1.888.114.08.226.165.366.27-.042.02-.085.037-.13.05-1.865.144-3.73.29-5.594.43C.284 5.655 0 5.932 0 6.675c.004 4.72.006 9.002.008 13.722 0 1.26-.004 2.935 0 4.215H.004v.313c0 .693.462 1.302 1.13 1.487l10.18 2.787 1.657.455c.026.007.058.007.09.013.033-.006.066-.006.098-.013l1.657-.455 10.173-2.787c.67-.185 1.134-.792 1.137-1.487v-.168-.018c.003-6.092.004-12.184.003-18.276.02-.218-.054-.434-.2-.596-.15-.16-.357-.254-.576-.255zm-8.016-3.386c.168-.12.376-.166.578-.13.203.035.382.15.5.32.202.363.076.82-.283 1.03-.762.496-1.68.696-2.58.56.573-.67 1.027-1.375 1.786-1.78zm-9.6.163c.218-.308.636-.397.96-.205.298.153.572.35.814.58.374.37.702.79 1.05 1.188l-.07.113c-.922.006-1.828-.077-2.583-.71-.302-.226-.377-.65-.17-.966zM22.52 8.974l.002 13.727c0 .755-.207 1.024-.928 1.212l-6.026 1.57c-.654.17-.94-.052-.94-.735-.004-1.57-.008-3.14-.01-4.71v-.426l-3.122.667c.012 1.36.007 3.143.005 4.503 0 .638-.29.868-.902.71-2.055-.53-4.11-1.064-6.163-1.6-.51-.078-.87-.538-.825-1.052-.002-4.522-.002-9.045 0-13.568 0-.84.364-1.12 1.19-.976 1.956.336 3.912.667 5.87.994.66.11.832.3.832.96v4.991l3.115-.748v-.377l.01-3.94c.002-.54.176-.775.703-.867 2.07-.358 4.14-.71 6.21-1.053 0 0 .98-.243.98.72z"
                                                    fill="#EE3124"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <h1 class="visuallyhidden">Gift Hero</h1>
                            </div>
                        </div>
                        <div class="header-mobile__nav-actions">
                            <div
                                class="header-widescreen__nav-account"
                                v-if="$page.props.auth.user"
                            >
<!--                                <div class="notifications">-->
<!--                                    <button-->
<!--                                        class="notifications__toggle"-->
<!--                                        type="button"-->
<!--                                        :class="{-->
<!--                                            'is-active':-->
<!--                                                notificationsPanelIsVisible,-->
<!--                                        }"-->
<!--                                        @click.stop="-->
<!--                                            toggleNotificationsPanel();-->
<!--                                            markNotificationsRead();-->
<!--                                        "-->
<!--                                        v-click-outside="closeUserMenu"-->
<!--                                    >-->
<!--                                        <span-->
<!--                                            class="notifications__toggle-icon"-->
<!--                                            :class="{-->
<!--                                                'notifications__toggle-icon&#45;&#45;unread':-->
<!--                                                    unreadNotifications,-->
<!--                                            }"-->
<!--                                        >-->
<!--                                            <svg-->
<!--                                                width="72"-->
<!--                                                height="72"-->
<!--                                                viewBox="0 0 72 72"-->
<!--                                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                            >-->
<!--                                                <title>-->
<!--                                                    icon-ui-notification-bell-->
<!--                                                </title>-->
<!--                                                <path-->
<!--                                                    d="M42.508 63c.812.13 1.236.674 1.26 1.536.101 3.751-4.135 7.464-7.245 7.464-3.404 0-7.455-3.673-7.287-7.455.036-.826.387-1.38 1.151-1.53L42.508 63zM13.85 43.634v-4.491c0-4.811-.157-9.626.057-14.43.338-7.812 4.873-13.25 12.725-16.594.996-.425 1.461-.805 1.546-1.866C28.51 2.06 33.772-.896 38.669.248c3.57.83 5.889 3.234 6.204 6.518.061.632.33.909.972 1.144 7.901 2.904 13.455 9.709 13.283 17.724-.127 5.751-.03 11.508-.033 17.262v.708c1.875.76 3.655 1.316 5.24 2.163 2.484 1.327 3.76 3.346 3.66 5.987-.054 1.383 0 2.766 0 4.149-.01 1.706-.968 2.591-2.86 2.593-19.116.005-38.232.005-57.35 0-1.711 0-2.7-.773-2.743-2.283-.054-1.978-.086-3.968.097-5.935.197-2.098 1.675-3.596 3.648-4.61 1.537-.792 3.253-1.321 5.063-2.034z"-->
<!--                                                    fill-rule="nonzero"-->
<!--                                                />-->
<!--                                            </svg>-->
<!--                                        </span>-->
<!--                                    </button>-->
<!--                                </div>-->
                            </div>
                            <template v-else>
                                <ul class="nav-links nav-links--action">
                                    <li>
                                        <Link :href="route('login')" title="Log In">Log In</Link>
                                    </li>
                                    <li>
                                        <Link :href="route('register')" title="Sign Up">Sign Up</Link>
                                    </li>
                                </ul>
                            </template>
                            <!-- TODO: Implement methods -->
                            <button
                                class="nav-links--toggle"
                                aria-label="Toggle Navigation"
                                type="button"
                                @click.stop="toggleSiteNav"
                                :class="{ 'is-active': siteNavIsVisible }"
                            >
                                <div class="bars">
                                    <span class="bar" aria-hidden="true"></span>
                                    <span class="bar" aria-hidden="true"></span>
                                    <span class="bar" aria-hidden="true"></span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="header-mobile__navigation"
                    :class="{ 'is-visible': siteNavIsVisible }"
                >
                    <div class="header-mobile__navigation--upper">
                        <template v-if="$page.props.auth.user">
                            <ul class="nav-links nav-links--primary">
                                <!-- Wish Lists -->
                                <li>
                                    <Link
                                        :href="route('user.giftlists', { username: user.username })"
                                        :class="{
                                            selected: route().current() === 'user.giftlists',
                                        }"
                                        title="Wish Lists"
                                        @click="toggleSiteNav"
                                    >Wish Lists</Link>
                                </li>
                                <!-- Confirmed Gifts -->
                                <li>
                                    <Link
                                        :href="route('gifts.by_you')"
                                        :class="{
                                            selected: [
                                                'gifts.by_you',
                                                'gifts.for_you',
                                            ].includes(route().current()),
                                        }"
                                        title="Confirmed Gifts"
                                        @click="toggleSiteNav"
                                    >Confirmed Gifts</Link>
                                </li>
                                <!-- Friends -->
                                <li>
                                    <Link
                                        :href="route('friends.index')"
                                        :class="{
                                            selected: [
                                                'Friends/Following',
                                                'Friends/Followers',
                                            ].includes($page.component),
                                        }"
                                        title="Friends"
                                        @click="toggleSiteNav"
                                    >Friends</Link>
                                </li>
                                <!-- Explore -->
                                <li>
                                    <Link
                                        :href="route('explore')"
                                        :class="{
                                            selected: [
                                                'explore',
                                                'explore.search',
                                            ].includes($page.component.name),
                                        }"
                                        title="Gift Ideas"
                                        @click="toggleSiteNav"
                                    >Gift Ideas</Link>
                                </li>
                            </ul>
                            <!-- Secondary -->
                            <ul class="nav-links nav-links--secondary">
                                <li>
                                    <Link
                                        :href="route('about-us')"
                                        :class="{
                                            selected:
                                                $page.component.name ===
                                                'AboutUs',
                                        }"
                                        title="About"
                                        >About</Link>
                                </li>
<!--                                <li>-->
<!--                                    <Link-->
<!--                                        :href="route('blog')"-->
<!--                                        :class="{-->
<!--                                            selected:-->
<!--                                                $page.component.name === 'Blog',-->
<!--                                        }"-->
<!--                                        title="Blog"-->
<!--                                        @click="toggleSiteNav"-->
<!--                                    >Blog</Link>-->
<!--                                </li>-->
                                <li>
                                    <a
                                        href="https://support.gifthero.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Help"
                                        @click="toggleSiteNav"
                                    >Help</a>
                                </li>
                            </ul>
                        </template>
                        <template v-else>
                            <!-- Main Navigation / Logged Out -->
                            <ul class="nav-links nav-links--primary">
                                <li>
                                    <Link
                                        :href="route('register')"
                                        title="Create a Wish List"
                                        >Create a Wish List</Link>
                                </li>
                                <li>
                                    <Link
                                        :href="route('friends.find-friends')"
                                        title="Find Friends"
                                        >Find Friends</Link>
                                </li>
                                <li>
                                    <Link
                                        :href="route('explore')"
                                        :class="{
                                            selected:
                                                $page.component.name ===
                                                'Explore',
                                        }"
                                        title="Gift Ideas"
                                        >Gift Ideas</Link>
                                </li>
                            </ul>
                            <!-- Secondary -->
                            <ul class="nav-links nav-links--secondary">
                                <li>
                                    <Link
                                        :href="route('about-us')"
                                        title="About"
                                        >About</Link>
                                </li>
<!--                                <li>-->
<!--                                    <Link :href="route('blog')" title="Blog"-->
<!--                                        >Blog</Link>-->
<!--                                </li>-->
                                <li>
                                    <a
                                        href="https://support.gifthero.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Help"
                                        >Help</a>
                                </li>
                            </ul>
                        </template>
                    </div>
                    <div class="header-mobile__navigation--lower">
                        <div
                            class="header-mobile__account"
                            v-if="$page.props.auth.user"
                        >
                            <Link
                                :href="route('profile.show')"
                                class="header-mobile__account--edit"
                                @click="toggleSiteNav"
                            >
                                <figure class="header-mobile__account--icon">
                                    <img
                                        :src="user.avatar.profile_photo"
                                        :alt="user.full_name"
                                        width="56"
                                        height="56"
                                    />
                                </figure>
                                <div class="header-mobile__account--info">
                                    <span class="block manage" type="button">Account Settings</span>
                                </div>
                            </Link>
                            <Link
                                class="header-mobile__account--logout"
                                :href="route('logout')"
                                @click.stop="logout"
                                >Log Out</Link>
                        </div>
                        <ul class="header-mobile__action" v-else>
                            <li>
                                <Link class="button" :href="route('login')">Log In</Link>
                            </li>
                            <li>
                                <Link class="button is-primary" :href="route('register')">Sign Up</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <!-- .header -->
        <!-- TODO: Notifications Panel -->
        <!-- @includeWhen($loggedin, 'common.notifications.notification-panel') -->
    </section>
</template>
<script setup>
import { Link, router } from "@inertiajs/vue3";
import { useRootStore } from "@/stores/root.js";
import { storeToRefs } from "pinia";
import { useAuthUser } from "@/composables/useAuthUser.js";

const { userMenuIsVisible } = storeToRefs(useRootStore());
const { siteNavIsVisible } = storeToRefs(useRootStore());
const { closeUserMenu, toggleSiteNav, toggleUserMenu } = useRootStore();
const user = useAuthUser();

// Methods
/**
 * Logout the current user
 */
const logout = () => {
    router.post(route("logout"));
};
</script>
