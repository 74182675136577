<!--Wish List card option menu-->
<template>
    <base-card-option-menu :item="item" :name="item.name" :uuid="item.uuid">
        <template #actions>
            <template v-if="$page.props.auth.user?.data">
                <template v-if="canMoveOrCopy">
                    <move-copy-item-to-wish-list :item="item" :is-owner="isOwner" :list-uuid="list.uuid" v-if="props.hasLists || userHasLists" />
                    <move-item-to-new-wish-list :item="item" :is-owner="isOwner" />
                </template>
                <hr class="popover__option--divider" v-if="canMoveOrCopy">
            </template>
            <copy-link :copy-url="route('item.detail', { item })" />
            <template v-if="isOwner">
                <hr class="popover__option--divider">
                <delete-item :item="item"></delete-item>
            </template>
        </template>
    </base-card-option-menu>
</template>
<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import BaseCardOptionMenu from "@/Components/OptionMenus/BaseCardOptionMenu.vue";
import CopyLink from "@/Components/Actions/CopyLink.vue";
import DeleteItem from "@/Components/Actions/DeleteItem.vue";
import MoveCopyItemToWishList from "@/Components/Actions/MoveCopyItemToWishList.vue";
import MoveItemToNewWishList from "@/Components/Actions/MoveItemToNewWishList.vue";

// Props
const props = defineProps({
    item: {
        type: Object,
        default: {}
    },
    hasLists: {
        type: Boolean,
        default: false
    },
    isSpoiled: {
        type: Boolean,
        default: false
    },
    isOwner: {
        type: Boolean,
        default: false
    },
    list: {
        type: Object,
        default: function () { return {} }
    }
});

// Computed
const canMoveOrCopy = computed(() => {
    /**
     * In "Confirmed Gifts" or in list spoiler mode,
     * we don't want to allow users to move their own items to another wish list.
     * You can copy the item though if you're not the owner.
     */
    return ( (!isPurchasesView.value || !props.isOwner) && !props.isSpoiled )
});

const currentView = computed(() => {
    return usePage().component;
});

// TODO
const isPurchasedForMyself = computed(() => {
    return false;
    // return this.$parent.purchasedForMyself
});

// TODO
const isPurchasesView = computed(() => {
    return (['MyGiftHero/ConfirmedGifts/PurchasesByYou', 'MyGiftHero/ConfirmedGifts/PurchasesForYou'].includes(currentView.value))
});

// TODO
const listIsSpoiled = computed(() => {
    return props.list.hasOwnProperty('isSpoiled') ? props.list.isSpoiled : false;
});

const userHasLists = computed(() => {
   return usePage().props.auth.user?.data?.wishlists?.length > 0 || usePage().props.auth.user?.data?.expertlists?.length > 0;
});
</script>
