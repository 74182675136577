<script setup>
import { computed, ref } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import { useAPIForm } from "@/composables/useAPIForm";
import { useModalogStore } from "@/stores/modalog";
import ImageUpload from "@/Components/Actions/ImageUpload.vue";
import Modalog from "@/Components/Modalogs/Modalog.vue";

const modalogStore = useModalogStore();

const props = defineProps({
    showModalogOnCancel: {
        type: Object,
        default: null,
        required: false,
    },
});

const form = useAPIForm({
    imageUuid: null,
});
const imageUpload = ref(null);
const user = usePage().props?.auth.user.data ?? null;

const hasHeaderPhoto = computed(() => {
    return user?.cover_photo ?? null;
});

const isConfirmDisabled = computed(() => {
    return modalogStore.isConfirmDisabled;
});

const cancel = () => {
    resetDropzone();

    if (props.showModalogOnCancel) {
        modalogStore.showModalog({
            type: props.showModalogOnCancel.type,
            props: props.showModalogOnCancel.props,
            isConfirmDisabled: props.showModalogOnCancel.isConfirmDisabled
        });
    } else {
        modalogStore.closeModalog();
    }
};

const imageUploaded = (imageUuid) => {
    form.imageUuid = imageUuid;
    modalogStore.isConfirmDisabled = false;
};

const openRemoveUserProfileHeaderImageModalog = () => {
    modalogStore.showModalog({
        type: 'ModalogConfirmRemoveHeaderImage',
        props: {
            content: 'Your header photo makes your user profile more personal. Do you really want to remove it?',
            routeForDelete: route('giftlists.deleted_cover_photo'),
            title: 'Remove Current Header Photo?',
            showModalogOnClose: 'ModalogUserProfileHeaderImageUpload',
            showModalogOnCloseProps: {
                isConfirmDisabled: false
            }
        }
    });
};

const resetDropzone = () => {
    imageUpload.value.removeAllFiles();
};

const saveUserProfileHeaderPhoto = () => {
    form.post(route('profile.update_cover_photo', { temp_photo_uuid: form.imageUuid }), {
        onBefore: () => {
            modalogStore.isThrobbing = true;
        },
        onSuccess: () => {
            modalogStore.closeModalog();
            resetDropzone();
            router.reload();
        },
        onFinish: () => {
            modalogStore.isThrobbing = false;
        },
    });
}
</script>
<template>
    <modalog confirm-button-text="Save" :has-header="true">
        <template #title>{{ hasHeaderPhoto ? 'Change Header Photo' : 'Upload Header Photo' }}</template>
        <template #content>
            <div class="margin-bottom-small margin-top-small">
                <image-upload ref="imageUpload" @image-uploaded="imageUploaded"></image-upload>
            </div>
            <div class="center-align whitespace-top-small">
                <a class="button is-small is-primary is-outlined" href="javascript:void(0)" @click.stop="openRemoveUserProfileHeaderImageModalog" v-if="hasHeaderPhoto">Remove current header photo</a>
            </div>
        </template>
        <template #cancelButton>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="saveUserProfileHeaderPhoto" :disabled="isConfirmDisabled">Save</button>
        </template>
    </modalog>
</template>
