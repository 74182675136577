<!--Friend Card-->
<!--
  - Profile link
  - Profile photo
  - Following status
  - Recent list activity
  - List count
-->
<template>
    <Transition name="fade">
        <div
            class="card"
            :class="{ 'list-grid__item': isListGridItem }"
            v-if="!isHidden"
            v-click-outside="clickOutside"
        >
            <div class="card__container">
                <!--Processing Text-->
                <div class="card__processing" v-if="isProcessing">
                    <div class="card__processing-label">
                        {{ isProcessingText }}
                    </div>
                </div>
                <!--User Profile-->
                <div class="card__profile">
                    <!--Profile Link-->
                    <Link
                        :href="
                            route('user.giftlists', { username: user.username })
                        "
                        class="card__profile--anchor"
                        :title="user.presentation_name"
                    >
                        <div class="profile-pic">
                            <img
                                :src="user.avatar.profile_photo"
                                :alt="user.presentation_name"
                            />
                        </div>
                        <div
                            class="profile-identifier"
                            v-if="user.is_organization"
                        >
                            <span class="profile-user__organization-badge"
                                >Organization</span
                            >
                        </div>
                        <h2 class="profile-display-name" role="heading">
                            {{ user.presentation_name }}
                        </h2>
                        <h3 class="profile-handle">@{{ user.username }}</h3>
                        <template v-if="isLoggedIn">
                            <span
                                class="profile-status status"
                                v-if="user.follower"
                                >Follows You</span
                            >
                        </template>
                    </Link>
                    <!--Following Status-->
                    <div class="card__profile--following-status">
                        <button
                            class="strip-btn btn-rounded btn-rounded--medium btn-rounded--share"
                            disabled
                            v-if="isMeMyselfAndI"
                        >
                            That's Me!
                        </button>
                        <FollowingStatusButton :user="user" v-else />
                    </div>
                </div>
                <!--Card Tools-->
                <footer class="card__tools">
                    <div class="card__tools--icon-label">
                        <span class="label"
                            >{{ user.list_count }}&nbsp;<template
                                v-if="user.list_count === 1"
                                >List</template
                            ><template v-else>Lists</template></span
                        >
                    </div>
                    <FriendCardOptionsMenu
                        v-if="!isMeMyselfAndI && isLoggedIn"
                        :user="user"
                    />
                </footer>
            </div>
        </div>
    </Transition>
</template>
<script setup>
import { computed, ref } from "vue";
import { Link, usePage } from "@inertiajs/vue3";

// TODO
// import ClickOutside from 'vue-click-outside'
import FriendCardOptionsMenu from "@/Components/OptionMenus/FriendCardOptionsMenu.vue";
import FollowingStatusButton from "@/Components/Actions/FollowingStatusButton.vue";

// Props
const props = defineProps({
    isListGridItem: {
        type: Boolean,
        default: true,
    },
    isOwner: {
        type: Boolean,
        default: false,
    },
    myUuid: {
        type: String,
        default: null,
    },
    user: {
        type: Object,
        default: function () {
            return {};
        },
    },
});

// Data
const isHidden = ref(false);

// Computed
const currentView = computed(() => {
    return usePage().component;
});

const isBlocked = computed(() => {
    return props.user.is_blocked;
});

const isFindFriendsView = computed(() => {
    return currentView.toString().startsWith("Friends/");
    // return ['Friends/Following', 'Friends/Followers'].includes(currentView);
});

const isLoggedIn = computed(() => {
    return usePage().props.auth.user?.data;
});

const isMeMyselfAndI = computed(() => {
    if (!props.user) {
        return false;
    }
    return props.user.uuid === usePage().props.auth.user?.data.uuid;

    // return props.user.uuid === props.myUuid;
});

const isMyFollowersList = computed(() => {
    return (
        currentView.toString() === "Friends/Followers" &&
        !this.user.hasOwnProperty("is_blocked")
    );
});

// TODO: Should not need
const isProcessing = computed(() => {
    return false;
    // return props.user.hasOwnProperty('is_processing') ? this.user.is_processing : false;
});

// TODO: Should not need
const isProcessingText = computed(() => {
    return props.user.hasOwnProperty("is_processing_text")
        ? props.user.is_processing_text
        : "Processing";
});

// TODO: Should not need
const shouldHideCardAfterProcessing = computed(() => {
    return false;
    // return ( this.$store.state.friends.unfollowed.includes(this.user.uuid) || this.$store.state.friends.blocked.includes(this.user.uuid) );
});

// TODO: Use route() helper instead
const userUrl = computed(() => {
    return `/${props.user.username}`;
});

// Methods
function clickOutside() {
    console.log("FriendsCard clickOutside");

    // TODO
    // this.$store.dispatch('cardOptionMenus/closeAll').then();
}

// export default {
//     watch: {
//         isProcessing: function () {
//             setTimeout(() => {
//                 this.$set(this.user, 'is_processing', false)
//                 this.$set(this.user, 'is_processing_text', '')
//
//                 /* Only hide the card in certain views. */
//                 if (this.shouldHideCardAfterProcessing) {
//                     this.isHidden = true
//                 }
//             }, 2000)
//         }
//     },
//     directives: {
//         ClickOutside
//     }
// }
</script>
