<script setup>
import { inject } from "vue";
import { router } from '@inertiajs/vue3';
import { useImageUpload } from "@/composables/useImageUpload";
import { useModalogStore } from "@/stores/modalog";
import ImageUpload from '@/Components/Actions/ImageUpload.vue';
import Modalog from '@/Components/Modalogs/Modalog.vue';
const route = inject('route')

const { cancel, dropzoneRef, form, imageUploaded, resetDropzone } = useImageUpload();
const modalogStore = useModalogStore();

const props = defineProps({
    list: {
        type: Object,
        default: function () { return {} }
    },
});

const openRemoveWishListImageModalog = () => {
    modalogStore.showModalog({
        type: 'ModalogConfirmRemoveWishlistImage',
        props: {
            list: props.list
        },
        isConfirmDisabled: false
    });
}

const saveWishListImage = () => {
    form.post(route('giftlists.update_photo', {
        giftlist: props.list.uuid,
        temp_photo_uuid: form.imageUuid
    }), {
        onBefore: () => {
            modalogStore.isThrobbing = true;
        },
        onSuccess: () => {
            modalogStore.closeModalog();
            resetDropzone();
            router.reload({
                force: true,
                forceFormData: true,
                replace: true,
            });
        },
    });
}
</script>
<template>
    <modalog confirm-button-text="Save" :has-header="true">
        <template #title>{{ props.list.masthead_photo ? 'Change Header Photo' : 'Upload Header Photo' }}</template>
        <template #content>
            <div class="margin-bottom-small margin-top-small">
                <image-upload ref="dropzoneRef" @image-uploaded="imageUploaded"></image-upload>
            </div>
            <div class="center-align whitespace-top-small">
                <a class="button is-small is-primary is-outlined" href="javascript:void(0)" @click.stop="openRemoveWishListImageModalog" v-if="props.list.masthead_photo">Remove current header photo</a>
            </div>
        </template>
        <template #cancelButton>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="saveWishListImage" :disabled="modalogStore.isConfirmDisabled">Save</button>
        </template>
    </modalog>
</template>
