<script setup>
import { Head, Link } from "@inertiajs/vue3";
import Bios from "@/Pages/AboutUs/Bios.vue";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const props = defineProps(["bios"]);
</script>

<template>
    <Head>
        <title>About Gift Hero</title>
        <meta name="fo-verify" content="ace4acac-728c-4f7a-b9c0-2bd0d3327c2c" />
        <meta
            name="description"
            content="Learn more about Gift Hero, a universal wish list management system."
        />
        <meta property="og:title" content="About Gift Hero" />
        <meta property="og:description" content="Learn more about Gift Hero." />
        <meta property="og:url" :content="route('about-us')" />
        <link rel="canonical" :href="route('about-us')" />
    </Head>

    <main role="main" id="app" class="main has-subtle-gradient">
        <div class="layout-about whitespace-top-large">
            <div class="container-wide stacked-children">
                <header class="layout-about__intro">
                    <h1 class="title">
                        The Best Wish List&nbsp;Ever<sup>&trade;</sup>
                    </h1>
                    <h2 class="subtitle">
                        Gift Hero delivers a universal wish list that’s simple
                        to navigate, aesthetically pleasing, and a
                        re&#8209;gifting&nbsp;squasher.
                    </h2>
                </header>
                <section
                    class="layout-about__explainer whitespace-top whitespace-bottom"
                >
                    <figure class="visual" data-aos="fade">
                        <img
                            src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/about/about-gh-mark.png'"
                            srcset="
                                https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/about/about-gh-mark.png    1x,
                                https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/about/about-gh-mark_2x.png 2x
                            "
                            alt="About Gift Hero"
                        />
                    </figure>
                    <div class="content">
                        <h3 class="section-title">What is Gift Hero?</h3>
                        <p>
                            Sure, you’ve used wedding and baby registries. But
                            what about all of life’s other gift-giving
                            occasions? A Gift Hero wish list is like a soft sell
                            for birthdays, Mother’s Day, and even Christmas—all
                            in one place. Your friends and family will
                            thank&nbsp;you.
                        </p>
                    </div>
                </section>
                <section
                    class="layout-about__explainer layout-about__explainer--switch whitespace-bottom-large"
                >
                    <figure class="visual" data-aos="fade">
                        <img
                            src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/about/about-gh-devices.png"
                            srcset="
                                https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/about/about-gh-devices.png    1x,
                                https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/about/about-gh-devices_2x.png 2x
                            "
                            alt="About Gift Hero"
                        />
                    </figure>
                    <div class="content">
                        <h3 class="section-title">
                            Why a Universal Wish List?
                        </h3>
                        <p>
                            You’ve made your lists and checked them twice...and
                            again...and again. Registering with multiple
                            retailers isn’t just a pain for you to maintain but
                            also a nuisance for your loved ones to decipher.
                            With Gift Hero, anytime you see something online
                            that you need or want, you can add that item to a
                            wish list using your desktop, smartphone,
                            or&nbsp;tablet.
                        </p>
                    </div>
                </section>
            </div>
            <section
                class="layout-about__bios whitespace-top whitespace-bottom-large"
            >
                <div
                    class="layout-about__bios--container container-wide stacked-children"
                >
                    <h3 class="section-title align-center whitespace-top-small">
                        The Team
                    </h3>
                    <div class="layout-about__bios--cards whitespace-top-small">
                        <Bios v-for="bio in bios" :bio="bio" :key="bio.id" />
                    </div>
                </div>
            </section>
            <section
                class="layout-about__disclosure whitespace-top-large whitespace-bottom-large"
            >
                <div
                    class="layout-about__disclosure--container container-wide stacked-children"
                >
                    <h3 class="section-title section-title__small">
                        Affiliate Disclosure
                    </h3>
                    <p>
                        Gift Hero is a participant in the Amazon Services LLC
                        Associates Program, an affiliate advertising program
                        designed to provide a means for sites to earn
                        advertising fees by advertising and linking to
                        Amazon.com.
                    </p>
                    <p>
                        Gift Hero also participates in the SkimLinks, CJ,
                        LinkShare, Impact Radius, ShareASale and Pepperjam
                        Network affiliate programs.
                    </p>
                </div>
            </section>
        </div>
    </main>
</template>

<style lang="scss">
@import "../../scss/landing/about";
</style>
