<template>
    <button
        class="button is-small is-expert is-rounded"
        @click="showCreateEditWishListModalog"
        v-if="props.isBasic"
    >
        <strong>Create an Expert List</strong>
    </button>
    <button
        class="button is-primary is-rounded is-inverted is-bold has-shadow has-icon"
        :class="listTypeClass"
        @click="showCreateEditWishListModalog"
        v-else
    >
        <i class="icon"><SvgIconPlus /></i>New
        {{ listTypeMoniker }} List
    </button>
</template>
<script setup>
import { computed } from 'vue';
import { useModalogStore } from "@/stores/modalog";;
import SvgIconPlus from '~/svg/icon-plus.svg'

const modalogStore = useModalogStore();
const { showModalog } = modalogStore;

const props = defineProps({
    isBasic: {
        type: Boolean,
        default: false,
        required: false,
    },
    type: {
        type: String,
        default: "wish",
        required: true,
        validation: function (value) {
            return value in ["expert", "wish"];
        },
    },
});

// --- Computed ---
const isBasic = computed(() => props.isBasic);

const listTypeClass = computed(() => {
    return props.type === "expert" ? "is-expert" : null;
});

const listTypeMoniker = computed(() => {
    return props.type.charAt(0).toUpperCase() + props.type.substr(1);
});

// --- Methods ---
function showCreateEditWishListModalog() {
    showModalog({
        type: "ModalogCreateEditWishList",
        props: {
            isEdit: false,
            listType: props.type,
        },
        isConfirmDisabled: true,
    });
};
</script>
