import { ref } from 'vue';
import { useAPIForm } from '@/composables/useAPIForm';
import { useModalogStore } from '@/stores/modalog';

export function useImageUpload() {
    const modalogStore = useModalogStore();
    const dropzoneRef = ref(null);

    const form = useAPIForm({
        imageUuid: null,
    });

    const cancel = () => {
        modalogStore.closeModalog();
        resetDropzone();
    };

    const imageUploaded = (imageUuid) => {
        form.imageUuid = imageUuid;
        modalogStore.isConfirmDisabled = false;
    };

    const resetDropzone = () => {
        dropzoneRef.value.removeAllFiles();
    };

    return {
        cancel,
        dropzoneRef,
        form,
        imageUploaded,
        resetDropzone,
    };
}
