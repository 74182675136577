<template>
    <Modalog :has-header="false" :has-inline-errors="true" contentClass="dialog__content--has-media" size="large" :transition-name="transitionName">
        <template #content>
            <div class="content-media">
                <div class="content-media__image">
                    <div class="add-item-media__upload">
                        <template v-if="!imageUploadVisible">
                            <!-- Shown by default: -->
                            <div class="add-item-media__upload--visual">
                                <img src="https://res.cloudinary.com/gift-hero/image/upload/v1502477767/gh-default-image-560x380_ljc6g3.png" alt="Default Gift Hero image" width="280" height="190" @click="showImageUpload()" />
                            </div>
                            <div class="add-item-media__upload--action">
                                <button class="button is-primary is-teensy is-inverted is-rounded has-shadow" @click="showImageUpload()">Upload Image</button>
                            </div>
                        </template>
                        <template v-else>
                            <ImageUpload
                                v-on:image-added="imageAdded"
                                v-on:image-removed="imageRemoved"
                                v-on:image-uploaded="imageUploaded"
                                v-on:image-upload-error="imageUploadError"
                                v-on:image-uploading="imageUploading"
                            />
                        </template>
                    </div>
                </div>
            </div>
            <div class="content-main">
                <!--Errors-->
                <div class="content-errors" v-if="hasApiErrors">
                    <!--<errors></errors>-->
                </div>
                <div class="dialog__content--row">
                    <!--Item name-->
                    <label class="form-label" for="item-name">Gift Name</label>
                    <input class="input" type="text" id="item-name" name="item-name" placeholder="Our Wedding Day" required v-model="props.custom.value.name" autocomplete="off">
                </div>
                <!--Item URL-->
                <div class="dialog__content--row" :class="{ 'is-error': hasErrorUrl }">
                    <label class="form-label is-optional" for="item-url">Link</label>
                    <input class="input" type="text" id="item-url" name="item-url" placeholder="https://www.coachella.com/guidebook/passes" required v-model="props.custom.value.url" autocomplete="off">
                </div>
                <!--URL validation errors-->
                <div class="dialog__content--row dialog__content--row-no-padding is-error" v-if="hasErrorUrl">
                    <div class="form-control__response">{{ hasErrorUrl }}</div>
                </div>
                <!--Item Price / Currency / Quantity-->
                <div class="dialog__content--row dialog__columns dialog__columns--two-thirds">
                    <!--Price / Currency-->
                    <div class="column">
                        <div class="dialog__columns dialog__columns--two">
                            <!--Item price-->
                            <div class="column">
                                <label class="form-label" for="item-price">Price</label>
                                <div class="input-group" :class="{ 'is-error': invalidPrice }">
                                    <div class="input-group-prepend">
                                        <span class="input-group__text">{{ $page.props.currencies[currencyCodeSelected] }}</span>
                                    </div>
                                    <input type="number" class="input" id="item-price" name="item-price" required pattern="[0-9]+(\.[0-9][0-9]?)?" min="0" step="any" v-model="props.custom.value.price" @input="validatePrice" autocomplete="off">
                                </div>
                            </div>
                            <div class="column column--align-bottom">
                                <user-currency-select :currency-code="currencyCodeSelected" @currency-code-selected="setSelectedCurrency"></user-currency-select>
                            </div>
                        </div>
                    </div>
                    <!--Quantity-->
                    <div class="column">
                        <template v-if="!isExpertList">
                            <label class="form-label" for="item-quantity">Qty</label>
                            <input class="input" type="number" id="item-quantity" name="item-quantity" defaultValue="1" min="1" pattern="\d*" required v-model="props.custom.value.qty_desired" autocomplete="off">
                        </template>
                    </div>
                </div>
                <!--Default currency selector-->
                <!--<default-currency-inline-modalog :currency-code="selectedCurrency.code" :currency-symbol="selectedCurrency.symbol" v-show="hasInlineModalog"></default-currency-inline-modalog>-->
                <!--Price Errors-->
                <div class="dialog__content--row dialog__content--row-no-padding is-error" v-if="invalidPrice">
                    <div class="form-control__response">Price can only contain numbers and a single period.</div>
                </div>
                <!--Item details-->
                <div class="dialog__content--row">
                    <label class="form-label is-optional" for="item-comments">Gift Details</label>
                    <textarea class="textarea" name="item-comments" placeholder="General Admission would be great!" rows="2" v-model="props.custom.value.notes" autocomplete="off">{{ itemComments }}</textarea>
                </div>
            </div>
        </template>
        <template #footer>
            <button class="strip-btn dialog__action-button" @click="closeModalog" v-if="!isLoading">Cancel</button>
            <throbber v-if="isLoading"></throbber>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" :disabled="!validData" @click="addItem" v-if="!isLoading">Save</button>
        </template>
    </Modalog>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { router, usePage } from "@inertiajs/vue3";
import { debounce } from "lodash";
// import DefaultCurrencyInlineModalog from '../../modalogs/DefaultCurrencyInlineModalog';
import ImageUpload from "@/Components/Actions/ImageUpload.vue";
import Modalog from "@/Components/Modalogs/Modalog.vue";
import Regex from '@/utilities/regex';
import Throbber from "@/Components/Throbber/Throbber.vue";
import UserCurrencySelect from '@/Components/forms/UserCurrencySelect.vue';
import { useModalogStore } from "@/stores/modalog";
import validUrl from 'valid-url';

//--- Emits
const emit = defineEmits(['hide-dialog']);

//--- Stores
const { closeModalog } = useModalogStore();

//--- Props
const props = defineProps(['custom', 'listType', 'listUuid']);

//--- State
const currencyCodeSelected = ref(usePage().props?.auth.user.data.preferred_currency ?? 'USD');
const dzFile = ref({});
const dzOptions = reactive({
    addRemoveLinks: true,
    dictDefaultMessage: 'Drag/Drop your image or click here to upload.',
    dictFileTooBig: 'Your image must be smaller than 8MB',
    headers: {
        // 'X-CSRF-Token': window.vuedata.csrf // TODO: Get rid of this code
        'X-CSRF-Token': usePage().props.auth.token,
    },
    maxFiles: 1,
    maxFilesize: 8,
    url: '/api/v1/items/custom/photo',
});
const errors = ref(null); // If the API fails, this will become an object of key/value errors.
const fileUploading = ref(false);
const imageUploadVisible = ref(false);
const isLoading = ref(false);
const isValidPrice = ref(true);
const itemComments = ref('');
const thumbnailHeight = ref(100);
const thumbnailWidth = ref(100);
const transitionName = ref(null);
const useCustomDzOptions = ref(true);

onMounted(() => {
    transitionName.value = 'fade';
});

//--- Computed
const defaultCurrency = computed(() => {
    return 'USD';
});

const hasApiErrors = computed(() => {
    return false;
    // return this.$store.state.api.hasErrors.length;
});

const hasErrorUrl = computed(() => {
    if (!errors || typeof errors !== 'object') {
        return false;
    } else {
        return errors.hasOwnProperty('url') ? errors.url[0] : false;
    }
});

// TODO: This is the inline modalog to save as your default currency when changing the value in the dropdown.
const hasInlineModalog = computed(() => {
    return false;
    // return this.$store.state.modalogs.hasInlineModalog;
});

const invalidPrice = computed(() => {
    return !isValidPrice;
});

const isExpertList = computed(() => {
    return props.listType === 'expert';
});

const isMobile = computed(() => {
    return false;
    return this.$store.state.isMobile;
});

const quantities = computed(() => {
    let data = [];

    for (let i = 1; i < 100; i++) {
        data.push(i);
    }

    return data;
});

const userPreferredCurrencyCode = computed(() => {
    return usePage().props?.auth.user.data.preferred_currency || 'USD';
});

const validData = computed(() => {
    return (props.custom.value.name.length > 0 && props.custom.value.qty_desired > 0 && isValidPrice.value && !fileUploading.value);
});

const validOptionalUrl = computed(() => {
    return ((props.custom.value.url.length && validUrl.isUri(props.custom.value.url)) || !props.custom.value.url.length > 0) ? true : false;
});

//--- Methods
function addItem () {
    isLoading.value = true;

    // Update currency code
    props.custom.value.currency_code = currencyCodeSelected.value;

    axios.post(route('item.store.custom'), props.custom.value)
        .then(() => {
            isLoading.value = false;

            closeModalog();

            // TODO: Handle `pagination` and `orderBy: sequence`
            router.reload({ only: ['items'] });
        })
        .catch(error => {
            console.log(error);
            isLoading.value = false;
            errors.value = error.response.data;
        });
    ;
};

function hideAddItemDialog () {
    emit('hide-dialog');
    closeModalog();
};

function imageAdded (file) {
    console.log(file);
};

function imageRemoved (file, error, xhr) {
    dzFile.value = {};
};

function imageUploaded (photo_uuid) {
    props.custom.value.photo_uuid = photo_uuid;
    fileUploading.value = false;
};

function imageUploadError(file) {
    console.log(file);
};

function imageUploading (file, xhr, formData) {
    console.log(file, xhr, formData);
    fileUploading.value = true;
};

function setSelectedCurrency (currencyCode) {
    currencyCodeSelected.value = currencyCode;
}

function showImageUpload () {
    imageUploadVisible.value = true;
};

const validatePrice = debounce( function () {
    // See `/_js/utils/regex.js`
    isValidPrice.value = props.custom.value.price.toString().search(Regex.priceInputs) >= 0; // Had to use `toString` in Vue 3
}, 500);

// TODO: Error handling
// export default {
//     watch: {
//         errors: function(errors) {
//             if (typeof errors !== 'object') {
//                 return false
//             }
//
//             // Scroll the container to the top
//             let container = document.getElementsByClassName('dialog__content')
//             if (container.length) {
//                 container.item(0).scrollTop = 0;
//             }
//         },
//         selectedCurrency (selectedCurrency) {
//             this.$store.commit('hasInlineModalog', true)
//         }
//     }
// }
</script>
