<template>
    <Transition :name="itemCardTransition">
        <KeepAlive>
            <div class="card card__item list-grid__item" :class="{ 'quantity-fulfilled': $page.component === 'Friends/WishList' && item?.qty_remaining === 0 }" v-click-outside="clickOutside" v-if="item && canBeDisplayed">
                <div class="card__container">
                    <transition name="fade">
                        <div class="card__processing" v-if="isProcessing">
                            <div class="card__processing-label" v-html="isProcessingText"></div>
                        </div>
                    </transition>
                    <template v-if="isPurchasesView || forceConfirmationAvatar">
                        <div class="card__confirmation">
                            <!--Purchaser-->
                            <div class="confirmation-avatar" v-if="showConfirmationAvatar">
                                <template v-if="isPurchasesForYou && purchaser">
                                    <span v-if="purchaser.is_blocked">
                                        <img :src="purchaser.avatar.profile_photo" height="26" width="26"
                                         :alt="purchaser.presentation_name"/>
                                    </span>
                                    <a :href="route('user.giftlists', { username: purchaser.username })" v-else>
                                        <img :src="purchaser.avatar.profile_photo" height="26" width="26"
                                             :alt="purchaser.presentation_name"/>
                                    </a>
                                </template>
                                <template v-else-if="isPurchasesByYou && purchasee">
                                    <span v-if="purchasee.is_blocked">
                                        <img :src="purchasee.avatar.profile_photo" height="26" width="26"
                                         :alt="purchasee.presentation_name"/>
                                    </span>
                                    <a :href="route('user.giftlists', { username: purchasee.username })" v-else>
                                        <img :src="purchasee.avatar.profile_photo" height="26" width="26"
                                             :alt="purchasee.presentation_name"/>
                                    </a>
                                </template>
                            </div>
                            <template v-if="purchasedForMyself">
                                <!--Purchased for Myself-->
                                <div class="confirmation-message" v-if="purchasedForMyself">
                                    for <span>Myself</span>
                                </div>
                            </template>
                            <template v-else-if="!purchasedForMyself">
                                <div class="confirmation-message">
                                    <template v-if="isPurchasesByYou">
                                        for
                                        <template v-if="purchasee">
                                            <span v-if="purchasee.is_blocked">{{ purchasee.presentation_name }}</span>
                                            <a :href="route('user.giftlists', { username: purchasee.username })"
                                               :title="`You confirmed this gift for ${purchasee.presentation_name}.`"
                                               v-else-if="purchasee">{{ purchasee.presentation_name }}</a>
                                        </template>
                                        <span v-else>Unknown User</span>
                                    </template>
                                    <template v-else-if="isPurchasesForYou">
                                        <template v-if="!purchaser"> by <strong>Unknown User</strong></template>
                                        <template v-else>
                                            by
                                            <span v-if="purchaser.is_blocked">{{ purchaser.presentation_name }}</span>
                                            <a :href="route('user.giftlists', { username: purchaser.username })"
                                               :title="`${purchaser.presentation_name} confirmed this gift for you.`"
                                               v-else>{{ purchaser.presentation_name }}</a></template>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                    <Link :href="route('item.detail', { item: item.uuid })" class="card__anchor" :title="item.name">
                        <!--Item Image-->
                        <div class="card__images card__images--single">
                            <div class="card__image card__images-full"
                                 :style="{ backgroundImage: 'url(' + item.image_url['img_2x'] + ')'}"></div>
                        </div>
                        <!--Item Info-->
                        <div class="card__txt">
                            <div class="card__txt--name" v-html="item.name.substring(0, 45)"></div>
                            <div class="card__txt--retailer" v-if="retailerName">{{ retailerName }}</div>
                            <div class="card__txt--price">
                                <span class="price-currency-symbol">{{ itemPriceSymbol }}</span>
                                <span class="price-numeric">{{ item.price }}</span>
                                <span class="price-currency-code">{{ item.currency_code }}</span>
                            </div>
                        </div>
                    </Link>
                    <!--Item Tools-->
                    <footer class="card__tools" :class="{ 'card__tools--flex-end': isGiftHero }">
                        <!--Date added-->
                        <div class="card__tools--icon-label" v-if="!isGiftHero">
                            <span class="icon"><svg-card-timestamp></svg-card-timestamp></span>
                            <span class="label">{{ dateAddedFormatted }}</span>
                        </div>
                        <!--Item Card Option Menu-->
                        <item-card-options-menu
                            v-if="cardOptionMenuIsVisible"
                            :has-lists="userHasLists"
                            :item="item"
                            :is-owner="isItemOwner"
                            :is-spoiled="isSpoiled"
                            :list="list"
                        />
                    </footer>
                </div>
            </div>
        </KeepAlive>
    </Transition>
</template>
<script setup>
import { computed, ref } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import ItemCardOptionsMenu from "@/Components/OptionMenus/ItemCardOptionsMenu.vue";
// import ClickOutside from 'vue-click-outside'
import dayjs from "dayjs";
import SvgCardTimestamp from '~/svg/ui-card-timestamp.svg'
// import urlUtils from '../mixins/urlUtils'
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";

const cardOptionsStore = useCardOptionsMenuStore();

// Props
const props = defineProps({
    forceConfirmationAvatar: {
        type: Boolean,
        default: false
    },
    hasLists: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        default: {},
    },
    isOwner: {
        type: Boolean,
        default: false
    },
    isSpoiled: {
        type: Boolean,
        default: false
    },
    list: {
        type: Object,
        default: function () { return {} }
    },
    owner: {
        type: Object,
        default: function () { return {} }
    },
    /**
     *  The user whom the item was purchased for.
     *  Only applies if `isPurchases`
     */
    purchasee: {
        type: Object,
        default: null
    },
    /**
     *  The user who purchased the item.
     *  Only applies if `isPurchases`
     */
    purchaser: {
        type: Object,
        default: null
    },
    showDeleted: {
        type: Boolean,
        default: false
    },
    userUuid: {
        type: String,
        default: null
    }
});

// State
const owner = props.owner?.data ?? usePage().props.owner?.data;
const isHidden = ref(false);
const item = ref(props.item);

const canBeDisplayed = computed(() => {
    return (!isHidden.value && !props.item.is_deleted) || props.showDeleted;
});

const cardOptionMenuIsVisible = computed(() => {
    return (!(isPurchasesByYou &&
        !purchasedForMyself &&
        props.item.hasOwnProperty('user')
        && props.item.user.is_blocked));
});

const dateAddedFormatted = computed(() => {
    return dayjs(props.item.created_at).format('MMM YYYY');
});

const itemPriceSymbol = computed(() => {
    return props.item.price_symbol;
});

const isGiftHero = computed(() => {
    return owner?.is_gift_hero !== 0 ?? false;
});

const isItemOwner = computed(() => {
    // If the `isOwner` prop is true, return true
    if (props.isOwner) {
        return true
    } else {
        if (props.userUuid && props.item.hasOwnProperty('user')) {
            return ( props.userUuid === props.item.user.uuid )
        }
    }
    return false
});

const isProcessing = computed(() => {
    return props.item.hasOwnProperty('is_processing') ? props.item.is_processing : false;
});

const isProcessingText = computed(() => {
    return props.item.hasOwnProperty('is_processing_text') ? props.item.is_processing_text : 'Processing';
});

const isPurchasesView = computed(() => {
    return (['gifts.by_you', 'gifts.for_you'].includes(route().current()));
});

const isPurchasesByYou = computed(() => {
    return route().current() === 'gifts.by_you';
});

const isPurchasesForYou = computed(() => {
    return route().current() === 'gifts.for_you' || ( props.list.hasOwnProperty('isSpoiled') && props.list.isSpoiled );
});

const itemCardTransition = computed(() => {
    return paginationIsThrobbing ? 'dialog' : 'fade';
});

const listOwner = computed(() => {
    return owner;
    // return this.$store.state.wishlists.wishlistOwner
});

const paginationIsThrobbing = computed(() => {
    return false;
    // return this.$store.state.pagination.isThrobbing;
});

const purchasedForMyself = computed(() => {
    return ( props.purchaser && props.purchasee ) && (props.purchaser.uuid === props.purchasee.uuid || props.purchaser.username === props.purchasee.username);
});

const retailerName = computed(() => {
    return (props.item.product.retailer.hasOwnProperty('name') && props.item.product.retailer.name !== 'custom_item') ? props.item.product.retailer.name : null;
});

// TODO: Double-check
const showConfirmationAvatar = computed(() => {
    if (props.forceConfirmationAvatar) {
        return true
    } else {
        return (isPurchasesForYou && props.purchaser) || (isPurchasesByYou && props.purchasee);
    }
});

// TODO: Is this ok?
const userHasLists = computed(() => {
    return owner?.list_count > 0;
    // return !!this.$store.state.wishlists.wishlists.hasOwnProperty('wish') || !!this.$store.state.wishlists.wishlists.hasOwnProperty('expert')
});

function clickOutside () {
    cardOptionsStore.closeAll();
};

function resolveProcessing () {
    setTimeout(() => {
        // Reset processing status.
        this.$set(this.item, 'is_processing', false);
        this.$set(this.item, 'is_processing_text', '');
        // Hide the item if it should be hidden.
        if (this.$store.state.items.hiddenItems.includes(this.item.uuid)) {
            this.$set(this.item, 'is_deleted', true);
            this.isHidden = true;
        }
    }, this.$store.state.api.processingMessageLength);
};


// export default {
//     watch: {
//         /* Watch if item is processing... */
//         isProcessing: 'resolveProcessing'
//     },
// }
</script>
